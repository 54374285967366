import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const life = () => import('@/views/life.vue')
const niceScene = () => import('@/views/niceScene.vue')
const intelligentLighting = () => import('@/views/intelligentLighting.vue')
const entertainment = () => import('@/views/entertainment.vue')
const otherScenarios = () => import('@/views/otherScenarios.vue')
const windSystem = () => import('@/views/windSystem.vue')
const comfortableLife = () => import('@/views/comfortableLife.vue')
const waterPurification = () => import('@/views/waterPurification.vue')
const radiationSystem = () => import('@/views/radiationSystem.vue')
const afterSalesService = () => import('@/views/afterSalesService.vue')
const exhibitionHall = () => import('@/views/exhibitionHall.vue')
const newsCenter = () => import('@/views/newsCenter.vue')
const technicalAdvantages = () => import('@/views/technicalAdvantages.vue')
const introduction = () => import('@/views/introduction.vue')
const newsdetail = () => import('@/views/newsdetail.vue')
const caseSelection = () => import('@/views/caseSelection.vue')
const caseDetail = () => import('@/views/caseDetail.vue')
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/Home',
    name: 'Home',
    component: Home
  },
  {
    path: '/life',
    name: 'life',
    component: life
  },
  {
    path: '/niceScene',
    name: 'niceScene',
    component: niceScene
  },
  {
    path: '/intelligentLighting',
    name: 'intelligentLighting',
    component: intelligentLighting
  }
  ,
  {
    path: '/entertainment',
    name: 'entertainment',
    component: entertainment
  },
  {
    path: '/otherScenarios',
    name: 'otherScenarios',
    component: otherScenarios
  },
  {
    path: '/windSystem',
    name: 'windSystem',
    component: windSystem
  }
  ,
  {
    path: '/comfortableLife',
    name: 'comfortableLife',
    component: comfortableLife
  },
  {
    path: '/waterPurification',
    name: 'waterPurification',
    component: waterPurification
  },
  {
    path: '/radiationSystem',
    name: 'radiationSystem',
    component: radiationSystem
  }
  ,
  {
    path: '/afterSalesService',
    name: 'afterSalesService',
    component: afterSalesService
  }
  ,
  {
    path: '/exhibitionHall',
    name: 'exhibitionHall',
    component: exhibitionHall
  }
  ,
  {
    path: '/newsCenter',
    name: 'newsCenter',
    component: newsCenter
  },
  {
    path: '/technicalAdvantages',
    name: 'technicalAdvantages',
    component: technicalAdvantages
  }
  ,
  {
    path: '/introduction',
    name: 'introduction',
    component: introduction
  }
  ,
  {
    path: '/newsdetail',
    name: 'newsdetail',
    component: newsdetail
  }
  ,
  {
    path: '/caseSelection',
    name: 'caseSelection',
    component: caseSelection
  }
  ,
  {
    path: '/caseDetail',
    name: 'caseDetail',
    component: caseDetail
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
