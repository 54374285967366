<template>
  <div class="home">
    <headPage />
    <!-- mh享家 -->
    <div class="lunbo">
      <div class="wrapper">
        <swiper ref="mySwiper" style="height: 100%" :options="swiperOptions">
          <swiper-slide v-for="(item, index) in focusImgList">
            <div :class="active == '{{index}}' ? 'swiperActive' : ''">
              <img
                class="backimg"
                style="display: block; width: 100%"
                :src="item.img"
              />
              <div class="info">
                <div class="title">{{ item.title1 }}</div>
                <div class="title2">{{ item.title2 }}</div>
                <div class="divider"></div>
                <div class="title3">
                  {{ item.title3 }}
                </div>
              </div>
            </div>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
    </div>
    <!-- 选择mh享家 -->
    <div style="position: relative" class="i3">
      <img
        class="img1"
        style="display: block"
        src="../assets/image/image6.jpg"
        alt=""
      />
      <div class="bgfont">MAGIC</div>
      <div class="bgfonts">HOME</div>

      <div class="divider1" data-aos="fade-up" aos-once="false"></div>
      <div class="divider2" data-aos="fade-up" aos-once="false"></div>
      <div
        class="p21"
        style="color: #d0ac32"
        data-aos="fade-up"
        aos-once="false"
      >
        选择MH享家
      </div>
      <div class="p22" style="color: black" data-aos="fade-up" aos-once="false">
        你的生活会发生什么改变
      </div>

      <div class="big">
        <div class="item" @click="jump('/comfortableLife')">
          <img src="../assets/image/image1.png" class="bg" />
          <img src="../assets/image/image10.png" class="icon" />
          <p class="p1">更舒适的</p>
          <p class="p2">生活环境</p>
        </div>
        <div class="item" @click="jump('/niceScene')">
          <img src="../assets/image/image1-2.png" class="bg" />
          <img src="../assets/image/image11.png" class="icon" />
          <p class="p1">更惬意的</p>
          <p class="p2">生活场景</p>
        </div>
        <div class="item" @click="jump('/life')">
          <img src="../assets/image/image1-3.png" class="bg" />
          <img src="../assets/image/image12.png" class="icon" />
          <p class="p1">更便捷的</p>
          <p class="p2">智慧生活</p>
        </div>
      </div>
      <!-- <div class="beselected">
        <div class="selected">
          <img
            class="pic3"
            style="position: absolute"
            src="../assets/image/image1.png"
          />
          <img
            class="icon1"
            style="position: absolute"
            src="../assets/image/image10.png"
            alt=""
          />
          <div class="select" @click="jump('/comfortableLife')">
            <div class="p31">更舒适的</div>
            <div class="p34 q">生活环境</div>
          </div>
        </div>
        <div class="selected">
          <img
            class="pic4"
            style="position: absolute"
            src="../assets/image/image1-2.png"
          />
          <img
            class="icon2"
            style="position: absolute"
            src="../assets/image/image11.png"
            alt=""
          />
          <div class="select" @click="jump('/niceScene')">
            <div class="p32">更惬意的</div>
            <div class="p34 w">生活场景</div>
          </div>
        </div>
        <div class="selected">
          <img
            class="pic5"
            style="position: absolute"
            src="../assets/image/image1-3.png"
          />
          <img
            class="icon3"
            style="position: absolute"
            src="../assets/image/image12.png"
            alt=""
          />
          <div class="select" @click="jump('/life')">
            <div class="p33">更便捷的</div>
            <div class="p34 e">智慧生活</div>
          </div>
        </div>
      </div> -->
    </div>
    <!-- mh展厅 -->
    <div style="position: reletive">
      <img
        style="display: block; width: 100%"
        src="../assets/image/image5.png"
      />
      <div class="middle3">
        <div class="title5" data-aos="fade-right">MH</div>
        <div class="title4" data-aos="fade-right">·展厅</div>
        <div class="title6" data-aos="fade-right">
          MH享家针对生态家居最核心的五大领域进行规划与设计，涵盖温湿度、空气、净水、灯光、智能；通过当下最先进的健康舒适系统、科技智能系统。让舒适理念落地生根，让客户身临其境的沉浸式体验。
        </div>
      </div>
      <div
        class="button"
        @click="jump('/exhibitionHall')"
        data-aos="fade-right"
      >
        <div class="button1">了解更多</div>
      </div>
    </div>
    <!-- 项目案例 -->
    <div style="position: relative">
      <img
        style="display: block; width: 100%"
        src="../assets/image/image1-1.png"
      />
      <div class="middle4">
        <div class="title7" @click="jump('/caseSelection')">项目案例</div>
        <div class="divider3"></div>
        <div class="bigbox">
          <div class="mid4Img" v-for="(item, index) in caseList">
            <div>
              <img :src="item.img" alt="" />
            </div>
            <p class="bgp1" data-aos="fade-up" aos-once="false">
              {{ item.city }}
            </p>
            <p class="bgp" data-aos="fade-up" aos-once="false">
              {{ item.name }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <footPage />
  </div>
</template>

<script>
import headPage from "@/components/head.vue";
import footPage from "@/components/foot.vue";
import homeData from "../../public/homeData.json";
export default {
  data() {
    return {
      newsList: homeData.newsList,

      caseList: homeData.caseList,
      focusImgList: homeData.focusImgList,
      tran: 0,
      active: 0,
      swiperOptions: {
        slidesPerView: 1,
        pagination: {
          el: ".swiper-pagination",
        },
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: true,
        },
        on: {
          transitionEnd: () => {
            console.log(this.page.activeIndex);
            this.active = this.page.activeIndex;
          },
        },
      },
    };
  },
  mounted() {},
  computed: {
    page() {
      return this.$refs["mySwiper"].$swiper;
    },
  },
  methods: {
    jump(url) {
      this.$router.push(url);
    },
    scroll(value) {
      this.tran = value;
    },
  },
  components: {
    headPage,
    footPage,
  },
};
</script>
<style lang="scss" scoped>
.bgfont {
  font-family: "SourceHanSansCN-Medium";
  color: #d0cecf;
  position: absolute;
  font-size: 151px;
  top: 0;
  left: 170px;
}
.bgfonts {
  font-family: "SourceHanSansCN-Medium";
  color: #d0cecf;
  position: absolute;
  font-size: 151px;
  top: 0;
  right: 200px;
}
/deep/ .swiper-pagination-bullet-active {
  width: 70px;
  border-radius: 20px;
}
.wrapper {
  --swiper-theme-color: #d0ac32 !important;
  --swiper-pagination-color: #d0ac32 !important;
}
.lunbo {
  position: relative;
  width: -webkit-fill-available;
  overflow: hidden;

  text-align: left;

  .backimg {
    position: relat;
  }
}
.wrapper {
  height: 638px;
  .info {
    z-index: 999;
    position: absolute;
    top: 180px;
    left: 100px;
  }
}
.big {
  position: absolute;
  bottom: 0px;
  left: 136px;
  right: 136px;
  height: 497px;
  display: flex;
  .item {
    flex: 1;
    position: relative;
    &:hover {
      z-index: 9999;
      transform: scale(1.1);
      box-shadow: 0 0 2px 3px rgba(100, 100, 100, 0.09);
    }
    .bg {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
    .icon {
      position: absolute;
      left: 34px;
      bottom: 144px;
      width: 45px;
    }
    .p1 {
      position: absolute;
      font-family: "SourceHanSansCN-Bold";
      font-size: 29px;
      bottom: 82px;
      left: 34px;
      color: white;
    }
    .p2 {
      position: absolute;
      font-family: "SourceHanSansCN-Medium";
      font-size: 29px;
      bottom: 45px;
      left: 34px;
      color: white;
    }
  }
}
.szp {
  right: 146px;
  bottom: 105px;
}
.shp {
  right: 16px;
  bottom: 50px;
}
.njp {
  right: 147px;
  bottom: 105px;
}
.bgp {
  right: 17px;
  bottom: 54px;
  width: 100px;
}
.bigbox {
  position: absolute;
  top: 194px;
  left: 50%;
  transform: translateX(-50%);
  width: 1229px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.img1 {
  width: 100%;
  height: 713px;
}
.select {
  cursor: pointer;
}
.title {
  font-size: 63px;
  color: #d0ac32;
  font-family: "SourceHanSansCN-Bold";
}
.title2 {
  font-family: "SourceHanSansCN-Bold";
  font-size: 63px;
  color: black;
  margin-top: -10px;
}
.title3 {
  font-family: "SourceHanSansCN-Light";
  color: black;
  width: 515px;
  margin-left: 5px;
  margin-top: 38px;
  font-size: 16px;
}

.divider {
  height: 2px;
  background-color: black;
  width: 58px;
  margin-left: 6px;
  margin-top: 14px;
}
.pic3 {
  display: block;
  left: 136px;
  right: 136px;
  bottom: 0px;
  width: 27.5%;
}
.pic4 {
  display: block;
  left: 552px;
  right: 136px;
  bottom: 0px;
  width: 27.5%;
}
.pic5 {
  display: block;
  left: 969px;
  right: 136px;
  bottom: 0px;
  width: 27.5%;
}
.p21 {
  position: absolute;
  top: 0;
  font-family: "SourceHanSansCN-Bold";
  font-size: 37px;
  width: 100%;
  top: 52px;
}
.p22 {
  position: absolute;
  top: 0;
  font-family: "SourceHanSansCN-Medium";
  font-size: 38px;
  width: 100%;
  top: 102px;
}
.divider1 {
  position: absolute;
  height: 2px;
  background-color: black;
  width: 50px;
  top: 114px;
  right: 448px;
}
.divider2 {
  position: absolute;
  height: 2px;
  background-color: black;
  width: 50px;
  top: 114px;
  left: 448px;
}
.icon1 {
  left: 171px;
  bottom: 144px;
  width: 45px;
}
.icon2 {
  left: 590px;
  bottom: 144px;
  width: 45px;
}
.icon3 {
  right: 468px;
  bottom: 141px;
  width: 45px;
}
.p31 {
  position: absolute;
  font-family: "SourceHanSansCN-Bold";
  font-size: 29px;
  bottom: 82px;
  left: 170px;
  color: white;
}
.p32 {
  position: absolute;
  font-family: "SourceHanSansCN-Bold";
  font-size: 29px;
  bottom: 82px;
  left: 588px;
  color: white;
}
.p33 {
  position: absolute;
  font-family: "SourceHanSansCN-Bold";
  font-size: 29px;
  bottom: 82px;
  right: 396px;
  color: white;
}
.i3 {
  .p34 {
    position: absolute;
    font-family: "SourceHanSansCN-Medium";
    font-size: 29px;
    bottom: 45px;
    color: white;
  }
  .q {
    left: 170px;
  }
  .w {
    left: 588px;
  }
  .e {
    right: 395px;
  }
}
.middle3 {
  text-align: left;
  position: absolute;
  top: 252px;
  right: 191px;
}
.title5 {
  font-size: 63px;
  color: #d0ac32;
  font-family: "SourceHanSansCN-Bold";
  position: absolute;
  top: 1446px;
  right: 354px;
}
.title4 {
  font-size: 56px;
  color: white;
  font-family: "SourceHanSansCN-Bold";
  right: 153px;
  top: 1453px;
  position: absolute;
  width: 200px;
}
.title6 {
  font-family: "SourceHanSansCN-Light";
  color: #fff;
  font-size: 16px;
  text-align: justify;
  justify-content: space-around;
  top: 1546px;
  position: absolute;
  width: 365px;
  left: -454px;
}
.button {
  position: absolute;
  width: 95px;
  height: 30px;
  border: 1px solid #d0ac32;
  border-radius: 20px;
  top: 1947px;
  right: 543px;
  background-color: #d0ac32;
}
.button1 {
  margin-top: 4px;
  color: black;
  font-family: "SourceHanSansCN-Medium";
  cursor: pointer;
  font-size: 15px;
}
.title7 {
  font-size: 38px;
  color: #d0ac32;
  font-family: "SourceHanSansCN-Bold";
  position: absolute;
  top: 67px;
  left: 135px;
  cursor: pointer;
}
.divider3 {
  position: absolute;
  height: 0.5px;
  background-color: black;
  width: 144px;
  top: 95px;
  left: 317px;
}
.mid4Img {
  position: relative;
  margin-bottom: 22px;
  &::after {
    content: "";
    position: absolute;
    right: -17px;
    top: 0;
    bottom: 0;
    width: 17px;
    background: #e0c671;
  }
  div {
    width: 553px;
    overflow: hidden;
    img {
      width: 569px;
      display: block;
    }
  }
  p {
    right: 150px;
    bottom: 102px;
    text-align: left;
    color: #fff;
    position: absolute;
    font-size: 36px;
    font-family: SourceHanSansCN-Light;
    &:last-child {
      width: 185px;
      right: 36px;
      bottom: 55px;
      font-size: 37px;
      font-family: SourceHanSansCN-medium;
    }
  }
}
</style>

