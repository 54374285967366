<template>
  <div class="foot-box" :class="srenType == 'pc' ? 'pcmin' : ''">
    <div class="bottom">
      <img class="logo" src="../assets/companyInfoImg/logow.png" alt="" />
      <div class="m1">
        <img src="../assets/companyInfoImg/image2-2.png" alt="" />
      </div>
    </div>
    <div class="phone">400-180-8025</div>

    <div class="plate">
      <div>
        <span class="bar1" @click="jump('/comfortableLife')"
          >舒适的生活环境</span
        >
        <p @click="jump('/comfortableLife')">舒适生活</p>
        <p @click="jump('/radiationSystem')">辐射系统</p>
        <p @click="jump('/windSystem')">新风系统</p>
        <p @click="jump('/waterPurification')">中央净水</p>
      </div>
      <div class="list_min">
        <span class="bar1" @click="jump('/niceScene')">惬意的生活场景</span>
        <p @click="jump('/niceScene')">惬意生活</p>
        <p @click="jump('/intelligentLighting')">智能灯光</p>
        <p @click="jump('/entertainment')">影音娱乐</p>
        <p @click="jump('/otherScenarios')">其他场景</p>
      </div>
      <div>
        <span class="bar1" @click="jump('/life')">便捷的智慧生活</span>
      </div>
      <div>
        <span class="bar2">项目案例</span>
      </div>
      <div>
        <span class="bar1" @click="jump('/afterSalesService')"
          >项目流程与售后</span
        >
      </div>
      <div>
        <span @click="jump('/exhibitionHall')">关于MH</span>
        <p @click="jump('/exhibitionHall')">MH展厅</p>
        <p @click="jump('/technicalAdvantages')">技术优势</p>
        <p @click="jump('/newsCenter')">新闻中心</p>
        <p @click="jump('/introduction')">企业简介</p>
      </div>
    </div>
    <div class="connects">
      <img class="connect" src="../assets/companyInfoImg/image4.jpg" alt="" />
      <div class="describe">MH官方公众号</div>
    </div>
  </div>
</template>
<script>
export default {
  created() {
    const flag = navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    if (flag) {
      console.log("移动端打开");
      this.srenType = "phone";
    } else {
      console.log("PC端打开");
      this.srenType = "pc";
    }
  },
  methods: {
    jump(url) {
      this.$router.push(url);
    },
  },
};
</script>
<style lang="scss" scoped>
.connects {
  position: absolute;
  right: 116px;
  top: 66px;
}
.bar1 {
  margin-right: 40px;
}
.bar2 {
  margin-right: 30px;
}
.connect {
  width: 130px;
}
.foot-box {
  position: relative;
  height: 360px;
  background-color: #d0ac32;
  overflow: hidden;
}
.logo {
  position: absolute;
  width: 84px;
  left: 136px;
  top: 62px;
}
.phone {
  position: absolute;
  left: 140px;
  top: 146px;
  color: white;
  font-size: 32px;
}

.plate {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 59px;
  left: 50%;
  transform: translateX(-50%);
  width: 762px;
  span {
    margin: 0;
    display: block;
    font-family: "SourceHanSansCN-Light";
    font-size: 16px;
    color: #f3e8d2;
    text-align: left;
    cursor: pointer;
    margin-bottom: 33px;
  }
  p {
    text-align: left;
  }
}

p {
  margin-bottom: 10px;
  color: #fff;
  font-family: "SourceHanSansCN-Light";
  cursor: pointer;
  font-size: 16px;
}
.describe {
  font-family: "SourceHanSansCN-Medium";
  right: 121px;
  font-size: 19px;
  top: 204px;
  color: white;
}
.m1 {
  img {
    position: absolute;
    width: 50px;
    left: 233px;
    top: 61px;
  }
}
.pcmin {
  min-width: 1300px;
}

@media (max-width: 500px) {
  .plate {
    width: 900px;
    margin-left: 70px;
    .bar1 {
      font-size: 20px;
    }
    .bar2 {
      font-size: 20px;
    }
  }
  .logo {
    left: 80px;
  }
  .phone {
    left: 80px;
  }
  .m1 {
    img {
      left: 190px;
    }
  }
  .connects {
    margin: 20px auto;
    top: 350px;
    left: 50%;
    transform: translateX(-50%);
  }
  .foot-box {
    height: 800px;
    width: 100%;
  }
  .connect {
    width: 300px;
  }
  .describe {
    font-size: 30px;
  }
}
</style>