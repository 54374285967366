import Vue from 'vue'
import VueAwesomeSwiper from 'vue-awesome-swiper/dist/vue-awesome-swiper'
// css同理
import 'swiper/css/swiper.min.css'
Vue.use(VueAwesomeSwiper)
import App from './App.vue'
import router from './router'
import './assets/font/font.css'
import 'lib-flexible'
import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
  ak: 'yerwmLGH9KXed8G7ExpfT8E9hRNxasGO'
})

// 你也可以在这里设置全局配置

Vue.config.productionTip = false

new Vue({
  router,
  render: function (h) {
    return h(App)
  }
}).$mount('#app')
