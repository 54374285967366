<template>
  <div class="bar-box">
    <img class="img" src="../assets/icon/logow.png" @click="jump('/Home')" />
    <div
      class="bar"
      :style="{
        height: tran ? '200px !important' : '0',
      }"
    >
      <div @click="jump('/comfortableLife')">
        舒适的生活环境
        <div class="secondmenu">
          <div class="li" @click.stop="jump('/comfortableLife')">舒适生活</div>
          <div class="li" @click.stop="jump('/radiationSystem')">辐射系统</div>
          <div class="li" @click.stop="jump('/windSystem')">新风系统</div>
          <div class="li" @click.stop="jump('/waterPurification')">
            中央净水
          </div>
        </div>
      </div>
      <div @click="jump('/niceScene')">
        惬意的生活场景
        <div class="secondmenu">
          <div class="li" @click.stop="jump('/niceScene')">惬意生活</div>
          <div class="li" @click.stop="jump('/intelligentLighting')">
            智能灯光
          </div>
          <div class="li" @click.stop="jump('/entertainment')">影音娱乐</div>
          <div class="li" @click.stop="jump('/otherScenarios')">其他场景</div>
        </div>
      </div>
      <div @click="jump('/life')">便捷的智慧生活</div>
      <div @click="jump('/caseSelection')">项目案例</div>
      <div @click="jump('/afterSalesService')">项目流程与售后</div>
    </div>
    <div class="bar-right">
      <!-- <img src="../assets/image/image13.png" alt="" /> -->
      <div>
        <img class="xiala" src="../assets/image/image14.png" @click="xiala" />
        <div class="secondmenu">
          <div class="li" @click.stop="jump('/exhibitionHall')">MH展厅</div>
          <div class="li" @click.stop="jump('/technicalAdvantages')">
            技术优势
          </div>
          <div class="li" @click.stop="jump('/newsCenter')">新闻中心</div>
          <div class="li" @click.stop="jump('/introduction')">企业简介</div>
        </div>
      </div>
      <div class="phone">
        <img class="connect" src="../assets/image/image15.png" alt />
        <p>400-180-8025</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tran: false,
    };
  },
  methods: {
    jump(url) {
      this.$router.push(url);
    },
    xiala() {
      const flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      if (flag) {
        console.log("移动端打开");
        this.srenType = "phone";
        this.tran = !this.tran;
      } else {
        console.log("PC端打开");
        this.srenType = "pc";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.phone {
  margin-top: 5px;
  p {
    font-family: "SourceHanSansCN-Light";
    color: #fff;
    font-size: 16px;
    position: absolute;
    width: 100px;
    top: -5px;
    left: 36px;
    display: none;
  }
}
.phone:hover {
  p {
    display: block;
  }
}
.bar-box {
  display: flex;
  justify-content: space-between;
  padding: 0px 136px;
  align-items: center;
  height: 92px;
  background-color: #d0ac32;
}
.img {
  width: 67px;
  cursor: pointer;
}
.bar {
  font-family: "SourceHanSansCN-Light";
  font-size: 13px;
  color: #f3e8d2;
  margin-left: 2px;
  height: auto !important;
  width: 717px;
  display: flex;
  justify-content: space-between;
  > div {
    line-height: 90px;
    cursor: pointer;
    display: block;
    padding: 0 24px;
    position: relative;
    &:hover {
      color: #000;
      font-family: "SourceHanSansCN-Medium";
      background-color: #b3942a;
      .secondmenu {
        background-color: #fff;
        border-radius: 15px;
        display: block;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 78px;
        line-height: 40px;
        padding: 3px;
        z-index: 999;
        color: #333;
        .li {
          position: relative;
          border-radius: 15px;
          width: 104px;
          height: 39px;
          &::after {
            content: "";
            position: absolute;
            bottom: -2px;
            width: 82px;
            height: 1px;
            background-color: #abaaaa;
            left: 50%;
            transform: translateX(-50%);
          }
          &:last-child {
            &::after {
              content: "";
              display: none;
            }
          }
          &:hover {
            background-color: #d0ac32;
            color: #fff;
          }
        }
      }
    }
  }
  .secondmenu {
    display: none;
  }
}
.bar-right {
  display: flex;
  align-items: center;
  img {
    width: 20px;
    margin-right: 30px;
    cursor: pointer;
  }
  .secondmenu {
    display: none;
  }
  > div {
    cursor: pointer;
    display: block;
    position: relative;
    line-height: 90px;
    &:hover {
      .secondmenu {
        background-color: #fff;
        border-radius: 15px;
        display: block;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 78px;
        line-height: 40px;
        padding: 3px;
        z-index: 999;
        color: #333;
        .li {
          position: relative;
          border-radius: 15px;
          width: 104px;
          height: 39px;
          &::after {
            content: "";
            position: absolute;
            bottom: -2px;
            width: 82px;
            height: 1px;
            background-color: #abaaaa;
            left: 50%;
            transform: translateX(-50%);
          }
          &:last-child {
            &::after {
              content: "";
              display: none;
            }
          }
          &:hover {
            background-color: #d0ac32;
            color: #fff;
          }
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .phone {
    display: none;
  }
  .bar-box {
    padding: 0 32px;
    height: 92px;
    position: relative;
  }
  .img {
    width: 40px;
  }
  .bar {
    flex-direction: column;
    width: 100%;
    transition: 0.3s;
    position: absolute;
    left: 0;
    right: 0;
    top: 200px;
    height: 0 !important;
    background-color: #fff;
    overflow: hidden;
    z-index: 9999;
    text-align: -webkit-center;
    background-color: rgba($color: #000, $alpha: 0.8);
    div {
      display: block;
      margin-right: 0px !important;
      margin-top: 90px;
      font-size: 30px;
      line-height: 92px;
      &:hover {
        .secondmenu {
          display: none;
        }
      }
    }
  }
  .connect {
    display: none;
  }
  .bar-box {
    height: 200px;
  }
  .img {
    width: 120px;
  }

  .bar-right {
    display: flex;
    align-items: center;
    img {
      width: 80px;
      margin-top: 40px;
    }
    > div {
      &:hover {
        .secondmenu {
          display: none;
        }
      }
    }
  }
}
</style>