<template>
  <div id="app" class="app" :class="srenType == 'pc' ? 'pcmin' : ''">
    <router-view />
  </div>
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";
export default {
  data() {
    return {
      srenType: "pc",
    };
  },
  created() {
    AOS.init({
      offset: 200,
      duration: 600,
      easing: "ease-in-sine",
      delay: 200,
      once: true,
    });
    const flag = navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    if (flag) {
      console.log("移动端打开");
      this.srenType = "phone";
    } else {
      console.log("PC端打开");
      this.srenType = "pc";
    }
  },
};
</script>

<style lang="scss">
// @media (max-width: 1440px) {
//   .app {
//     transform: scale(0.9);
//   }
// }

// @media (max-width: 1500px) {
//   .app {
//     transform: scale(0.8);
//   }
// }

// @media (max-width: 1300px) {
//   .app {
//     transform: scale(0.5);
//   }
// }
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  // max-width: 1540px;
  margin: 0 auto;
  // position: relative;
}
.pcmin {
  min-width: 1300px;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
